import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from './features/auth/authSlice'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import Menu from './components/Menu'
import { FaHome } from 'react-icons/fa'

const Navbar = ({prevPage, messageAndLoggedOut, setMessageAndLoggedOut }) => {
  const location = useLocation();
  const path = location.pathname;
  const currentPage = path.split('/').pop();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useSelector((state) =>
    state.auth
  )
  if(!prevPage) {
    prevPage = 'channels'
  }
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/logout')
  }
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    /* If non-logged in user sends a message, open menu and reset state */
    if(messageAndLoggedOut) {
      setMenuOpen(true)
      setMessageAndLoggedOut(false)
    }
  }, [messageAndLoggedOut])

  return (
    <>
      <Menu menuOpen={menuOpen} toggleMenu={toggleMenu} onLogout={onLogout} />
      <nav>
        <div className="back-button">
          { currentPage != 'channels' || currentPage != 'sports' ?
            (<Link to={{ pathname: `/${prevPage}` }}><FaHome size="1.2rem"/></Link>) : null
          }
        </div>
        <div className="nav-logo">
          <img src="/chatwatch-word.png" alt="" />
        </div>
        <div className="bars-container">
          <FaBars color="white" size="1.2rem" onClick={toggleMenu} />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
