let channels = [
    { name: 'ABC', link: 'ABC-East', callSign: 'WABC'},
    { name: 'CBS', link: 'CBS-East', callSign: 'WCBS'},
    { name: 'NBC', link: 'NBC-East', callSign: 'WNBC'},
    { name: 'FOX', link: 'FOX-East', callSign: 'WNYW'},
    { name: 'BBC America', link: 'BBC-America', callSign: 'BBCA'},
    { name: 'TBS', link: 'TBS-East', callSign: 'TBS'},
    { name: 'TNT', link: 'TNT-East', callSign: 'TNT'},
    { name: 'ESPN', link: 'ESPN', callSign: 'ESPN'},
    { name: 'CNN', link: 'CNN', callSign: 'CNN'},
    { name: 'Fox News', link: 'Fox-News', callSign: 'FNC'},
    { name: 'CNBC', link: 'CNBC', callSign: 'CNBC'},
    { name: 'MSNBC', link: 'MSNBC', callSign: 'MSNBC'},
    { name: 'ESPN2', link: 'ESPN2', callSign: 'ESPN2'},
    { name: 'ESPNU', link: 'ESPNU', callSign: 'ESPNU'},
    { name: 'CBS Sports', link: 'CBSSN', callSign: 'CBSSN'},
    { name: 'A&E', link: 'AETV', callSign: 'AETV'},
    { name: 'AMC', link: 'AMC', callSign: 'AMC'},
    { name: 'Animal Planet', link: 'Animal-Planet', callSign: 'APL'},
    { name: 'BET', link: 'BET', callSign: 'BET'},
    { name: 'Bloomberg', link: 'Bloomberg', callSign: 'BLOOM'},
    { name: 'Bravo', link: 'Bravo', callSign: 'BRAVO'},
    { name: 'CMT', link: 'CMT', callSign: 'CMTV'},
    { name: 'Comedy Central', link: 'Comedy-Central', callSign: 'COMEDY'},
    { name: 'Cooking', link: 'Cooking', callSign: 'COOK'},
    { name: 'CSPAN', link: 'CSPAN', callSign: 'CSPAN'},
    { name: 'CSPAN2', link: 'CSPAN2', callSign: 'CSPAN2'},
    { name: 'CW', link: 'CW', callSign: 'CW'},
    { name: 'Destination America', link: 'Destination-America', callSign: 'DEST'},
    { name: 'Discovery', link: 'Discovery', callSign: 'DSC'},
    { name: 'E!', link: 'E', callSign: 'E'},
    { name: 'ESPN News', link: 'ESPN-News', callSign: 'ESPNEWS'},
    { name: 'Food Network', link: 'Food-Network', callSign: 'FOOD'},
    { name: 'Freeform', link: 'Freeform', callSign: 'FREEFRM'},
    { name: 'FX', link: 'FX', callSign: 'FX'},
    { name: 'FXM', link: 'FXM', callSign: 'FXM'},
    { name: 'FXX', link: 'FXX', callSign: 'FXX'},
    { name: 'Golf', link: 'Golf', callSign: 'GOLF'},
    { name: 'GSN', link: 'GSN', callSign: 'GSN'},
    { name: 'Hallmark', link: 'Hallmark', callSign: 'HALL'},
    { name: 'HBO', link: 'HBO', callSign: 'HBO'},
    { name: 'HBO2', link: 'HBO2', callSign: 'HBO2'},
    { name: 'HBO Family', link: 'HBOF', callSign: 'HBOF'},
    { name: 'HBO Signature', link: 'HBO-Signature', callSign: 'HBOSIG'},
    { name: 'HGTV', link: 'HGTV', callSign: 'HGTV'},
    { name: 'History', link: 'History', callSign: 'HISTORY'},
    { name: 'HLN', link: 'HLN', callSign: 'HLN'},
    { name: 'ID', link: 'ID', callSign: 'ID'},
    { name: 'IFC', link: 'IFC', callSign: 'IFC'},
    { name: 'Ion', link: 'Ion', callSign: 'ION'},
    { name: 'Lifetime', link: 'Lifetime', callSign: 'LIFE'},
    { name: 'Cinemax', link: 'Cinemax', callSign: 'MAX'},
    { name: 'MyTV', link: 'MyTV', callSign: 'MNT'},
    { name: 'More Max', link: 'More-Max', callSign: 'MOMAX'},
    { name: 'MTV', link: 'MTV', callSign: 'MTV'},
    { name: 'MTV2', link: 'MTV2', callSign: 'MTV2'},
    { name: 'MTV Classic', link: 'MTV-Classic', callSign: 'MTVCLAS'},
    { name: 'NBC Sports', link: 'NBCSN', callSign: 'NBCSN'},
    { name: 'National Geographic', link: 'National-Geographic', callSign: 'NGC'},
    { name: 'News Nation', link: 'News-Nation', callSign: 'NWSNTSD'},
    { name: 'On Patrol Live', link: 'On-Patrol-Live', callSign: 'OPLIVE'},
    { name: 'Outdoors Channel', link: 'Outdoors Channel', callSign: 'OUTD'},
    { name: 'OWN', link: 'OWN', callSign: 'OWN'},
    { name: 'Oxygen', link: 'Oxygen', callSign: 'OXYGEN'},
    { name: 'Paramount Network', link: 'Paramount', callSign: 'PAR'},
    { name: 'PBS', link: 'PBS-Detroit', callSign: 'PBS'},
    { name: 'Pop', link: 'Pop', callSign: 'POPSD'},
    { name: 'Science', link: 'Science', callSign: 'SCIENCE'},
    { name: 'Showtime2', link: 'Showtime2', callSign: 'SHO2'},
    { name: 'Showcase', link: 'Showcase', callSign: 'SHOCSE'},
    { name: 'Showtime', link: 'Showtime', callSign: 'SHOW'},
    { name: 'Showtime Extreme', link: 'Showtime-Extreme', callSign: 'SHOWX'},
    { name: 'Starz', link: 'Starz', callSign: 'STARZ'},
    { name: 'Starz Encore', link: 'Starz-Encore', callSign: 'STZENC'},
    { name: 'Sundance', link: 'Sundance', callSign: 'SUNDANC'},
    { name: 'SyFy', link: 'SyFy', callSign: 'SYFY'},
    { name: 'TCM', link: 'TCM', callSign: 'TCM'},
    { name: 'TLC', link: 'TLC', callSign: 'TLC'},
    { name: 'TMC', link: 'TMC', callSign: 'TMC'},
    { name: 'TMC Xtra', link: 'TMCX', callSign: 'TMCX'},
    { name: 'Travel', link: 'Travel', callSign: 'TRAV'},
    { name: 'TruTV', link: 'TruTV', callSign: 'TRUTV'},
    { name: 'TV Land', link: 'TV-Land', callSign: 'TVLAND'},
    { name: 'TV One', link: 'TV-One', callSign: 'TVONE'},
    { name: 'USA', link: 'USA', callSign: 'USA'},
    { name: 'VH1', link: 'VH1', callSign: 'VH1'},
    { name: 'Vice', link: 'Vice', callSign: 'VICE'},
    { name: 'WE', link: 'WE', callSign: 'WE'},
    { name: 'Weather', link: 'Weather', callSign: 'WEATH'},































































];

export default channels;