import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import channels from './Channels';
import ChannelsFilter from './components/ChannelsFilter'
import { TitleContext } from './app/TitleContext';
import { HiUsers } from 'react-icons/hi2';
import loadingSpinner from './png/loading.gif'
import live from './png/live.png'

const ProgramList = ({ livePrograms, userCounts, loading, page }) => {
    const navigate = useNavigate();
    const navigateToRoom = (channelLink) => {
        navigate(`/chat/${channelLink}`);
    };
    const { pageTitle, updatePageTitle } = useContext(TitleContext);
    const [allData, setAllData] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [channelData, setChannelData] = useState(null);
    const [filterValue, setFilterValue] = useState('');
    const [flagValues, setFlagValues] = useState([]);
    const [showList, setShowList] = useState(false);
    const [channelCount, setChannelCount] = useState(0);
    const [prevPath, setPrevPath] = useState('channels');

    const clearFilters = () => {
        setFilterValue('')
        setSearchValue('')
    }
    useEffect(() => {
        if (!loading) {
            setShowList(true)
        }
    }, [loading])

    // useEffect(() => {
    //     if (showList) {
    //       const timer = setTimeout(() => {
    //         setChannelCount(channelCount+1);
    //       }, 100); // Delay the initial display of items by 500ms (adjust as needed)

    //       return () => clearTimeout(timer);
    //     } else {
    //       setChannelCount(0);
    //     }
    //   }, [showList, channelCount]);

    useEffect(() => {
        updatePageTitle('Chatwatch - Channels')
    }, [])

    useEffect(() => {
        if (page === 'channels') {
            channels.forEach((channel) => {
                channel.image = require(`./png/${channel.callSign}.png`);
                channel.liveProgram = '';
                channel.userCount = 0;
                if (livePrograms.length > 0) {
                    let thisProgram = livePrograms.find(program => program.callSign === channel.callSign && program.liveNow === true);
                    if (thisProgram) {
                        channel.liveProgram = thisProgram;
                    }
                    if (userCounts) {
                        channel.userCount = userCounts[channel.link]?.length || 0;
                    }
                }
            });
            setAllData(channels);
            if (!channelData) {
                setChannelData(channels)
            }
        }
        if (page === 'sports') {
            // start with empty channel array rather than imported channels
            setPrevPath('sports');
            let sportChannels = [];
            const currentDate = new Date();
            // Reset upcoming events each Wednesday
            const nextWednesday = new Date(currentDate);
            nextWednesday.setDate(currentDate.getDate() + (3 + 7 - currentDate.getDay()) % 7); // 3 represents Wednesday
            livePrograms.forEach((event) => {
                const eventStartTimeString = event.startTime;
                const eventEndTimeString = event.endTime;
                const eventStartTime = new Date(eventStartTimeString);
                const eventEndTime = new Date(eventEndTimeString);
                if (currentDate <= eventEndTime && eventStartTime < nextWednesday) {
                    let live = false;
                    if (eventStartTime < currentDate && eventEndTime > currentDate) {
                        live = true;
                    }
                    sportChannels.push({
                        name: event.showName,
                        link: event.showName.replace(/^NFL: /, '').replace(/ /g, '-').toLowerCase(),
                        image: require(`./png/nfl/${event.image1}.svg`),
                        image2: require(`./png/nfl/${event.image2}.svg`),
                        liveProgram: event,
                        live: live,
                        userCount: 0
                    })
                }
            })
            sportChannels.forEach((channel) => {
                if (userCounts) {
                    channel.userCount = userCounts[channel.link]?.length || 0;
                }
            })
            setAllData(sportChannels);
            if (!channelData) {
                setChannelData(sportChannels)
            }
        }
    }, [livePrograms]);

    // Search and filter

    useEffect(() => {
        console.log(allData)
        if (allData && livePrograms.length > 0) {
            let filteredChannels = allData;
            if (filterValue === 'sports') {
                filteredChannels = filteredChannels.filter((channel) =>
                    channel.liveProgram.filter.includes('filter-sports')
                );
            } else if (filterValue === 'news') {
                filteredChannels = filteredChannels.filter((channel) =>
                    channel.liveProgram.filter.includes('filter-news')
                );
            } else if (filterValue === 'popular') {
                filteredChannels = filteredChannels.filter((channel) => channel.userCount > 0);
            }

            // // Text search
            // filteredChannels = filteredChannels.filter(
            //     (channel) =>
            //         channel.liveProgram.showName.toLowerCase().includes(searchValue.toLowerCase()) ||
            //         channel.name.toLowerCase().includes(searchValue.toLowerCase())
            // );

            filteredChannels.sort((a, b) => b.userCount - a.userCount); // Sort by userCount in descending order

            setChannelData(filteredChannels);
        }
    }, [searchValue, filterValue, allData, livePrograms]);

    return (
        <div>
            <Navbar prevPath={prevPath} />
            <ChannelsFilter
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                clearFilters={clearFilters}
            />
            <div className="channels-container">
                {loading || !channelData ? (
                    <div className="loading">
                        <img src={loadingSpinner} style={{ width: '50px' }} alt="Loading" />
                    </div>
                ) : (
                    <ul className={`channel-list ${showList ? 'fade-in' : ''}`}>
                        {channelData.map((channelData, index) => (
                            <div key={channelData.name} className='channel-link' onClick={() => navigateToRoom(channelData.link)}>
                                <div className='channel-list-row-back'>
                                    <div className="channel-list-row">
                                        {channelData.image2 ? (
                                            <div className="sports-vs-image">
                                                <img src={channelData.image} alt={channelData.name} /><img src={channelData.image2} alt={channelData.name} />
                                            </div>
                                        ) : (
                                            <div className="channel-list-image">
                                                <img src={channelData.image} alt={channelData.name} />
                                            </div>)
                                        }
                                        <div className="channel-list-program">
                                            <div className="channel-list-program-container">
                                            { channelData.live && <img src={live} />}
                                            <h3>{channelData.liveProgram.showName}</h3>
                                            </div>
                                        </div>
                                        <div className="channel-list-count">
                                            <HiUsers color='red' size='1.1rem' />
                                            <h3>{channelData.userCount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ProgramList;
